export const nl = {
    main: {
        language: "Nederlands",
        searchbar: 'Zoek (min. 3 karakters)',
        title: "Admin",
        logout: "Uitloggen",
        english: "Engels",
        dutch: "Nederlands",
        french: "Frans",
        spanish: "Spaans",
        danish: "Deens",
        romanian: "Roemeens",
        italian: "Italiaans",
        drop_image: "Sleep je afbeeldingsbestand hier",
    },
    ra: {
        tree: {
            add_child_node: 'Subcategorie toevoegen',
            add_node_after: 'Categorie toevoegen na',
            add_node_before: 'Categorie toevoegen voor',
            close: 'Sluiten',
            expand: 'Openen',
        },
    },
    resources: {
        categories: {
            name: "Categorie |||| Categorieën",
            fields: {
                name: 'Categorienaam',
            }
        },
        users: {
            name: "Gebruiker |||| Gebruikers",
            fields: {
                category: 'Categorie',
                email: 'E-mail',
                familyName: 'Achternaam',
                firstName: 'Voornaam',
                language: 'Taal',
                userRoles: 'Roles',
            }
        },
        priorities: {
            name: "Prioriteit |||| Prioriteiten",
        },
        branding: {
            name: "Logo"
        },
    },
}