import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React from 'react';

export default (props) => (
    <Grid container direction="column" justify="space-between" alignItems="center" wrap="nowrap" >
        <Grid item style={{ padding: '5px' }}>
            <a href={props.href} target={props.href === process.env.REACT_APP_AUTH0_REDIRECT_URI ? undefined : '_blank'} style={{ textDecoration: 'none' }}>
                <Button style={{
                    backgroundColor: props.backgroundColor,
                    border: '1px solid #dad4d4',
                    color: 'white',
                    width: '100px',
                    height: '80px',
                    padding: '10px',
                    textTransform: 'capitalize'
                }}>
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                        <span><props.icon /></span>
                        <span>{props.value}</span>
                    </span>
                </Button>
            </a>
        </Grid>
    </Grid>
)