import { fetchUtils, GET_ONE, UPDATE } from 'react-admin';
import auth from '../auth';
import fakeDataProvider from './fakeDataProvider';

const httpClient = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    options.headers.set('Authorization', `Bearer ${auth.accessToken}`)
    return fetchUtils.fetchJson(url, options);
}


const brandingDataProvider = async (type, resource, params) => {
    if (!auth.apiUrl) {
        return fakeDataProvider(type, resource, params);
    }
    const apiUrl = `${auth.apiUrl}/api`
    switch (type) {
        case GET_ONE:
            const result = await httpClient(`${apiUrl}/branding`).then(response => response.json)
            return { data: { id: params.id, logo: result.logo, colorText: result.colorText } }

        case UPDATE:
            const data = await fetch(`${apiUrl}/branding`, {

                body: JSON.stringify(params.data),
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`
                },
            })
                .then(response => response.json()).then(data => ({ data: { id: params.id, logo: data.logo, colorText: data.colorText } }))
                .catch(error => {
                    console.error('Error during branding logo updating:', error);
                });
            return data
        default:
            throw new Error(`Not implemented`)
    }
}

export default brandingDataProvider;