import fakeDataProvider from 'ra-data-fakerest';

const fake = fakeDataProvider({
    roles: [
        {
            name: "user",
            description: "uses the app",
        },
        {
            name: "supervisor",
            description: "supervises the users",
        },
        {
            name: "administrator",
            description: "administrates the app.",
        },
    ],
    users: [
        {
            firstName: "Geert",
            familyName: "S",
            email: 'geert@gemsotec.com',
        },
        {
            firstName: "Mark",
            familyName: "N",
            email: 'mark@gemsotec.com',
        },
        {
            firstName: "Stefan",
            familyName: "R",
            email: 'stefan@gemsotec.com',
        }
    ],
    categories: [
        {
            id: 1,
            name: "Safety",
        },
        {
            id: 2,
            name: "Toonzal 1",
            parentId: 1,
        },
        {
            id: 3,
            name: "Toonzal 03",
            parentId: 1,
        },
        {
            id: 4,
            name: "Toonzal 0",
            parentId: 2,
        },
        {
            id: 5,
            name: "Toonzal 5",
        },
        {
            id: 6,
            name: "Toonzal 6",
            parentId: 3,
        },
        {
            id: 7,
            name: "Toonzal 7",
            parentId: 5,
        },
    ],
    priorities: [
        {
            id: "123",
            highPriorityLabel: "",
            mediumPriorityLabel: "",
            lowPriorityLabel: ""
        },
    ],
    branding: 
        {
            id: "0.7174205889804688", 
            logo: "585fad14-711e-407f-9ec8-1069b53cad4d"
        }
})

export default (type, resource, params) => {
    if (resource === 'meta') {
        return Promise.resolve()
    }
    return fake(type, resource, params)
}