import { Tree, TreeList, TreeNode } from 'ra-tree-ui-materialui';
import React from 'react';
import { TextField } from 'react-admin';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import auth from '../auth';
import CategoryListActions from './CategoryListActions';
import { CategoryNodeActions } from './CategoryNodeActions';

const canDrag = () => false;

export const CategoryList = ({ accessible, ...props }) => {
    if (!accessible) {
        sessionStorage.landingUrl = props.history.location.pathname
        return (<Redirect to="/login" />)
    }
    if (auth.userHasScopes(["read:categories"])) {
        return (<Tree {...props} actions={<CategoryListActions />} exporter={false} parentSource="parentId">
            <TreeList>
                <TreeNode
                    actions={<CategoryNodeActions />}
                    canDrag={canDrag}
                >
                    <TextField source="name" />
                </TreeNode>
            </TreeList>
        </Tree>);
    }
    return (<div></div>)
}

const mapStateToProps = (state) => {
    return ({
        accessible: !!state.auth.profile,
    })
}

export default connect(mapStateToProps)(CategoryList)