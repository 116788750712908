// import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { AppBar, translate } from 'react-admin';
import Menu from './Menu';
import MyUserMenu from './MyUserMenu';


const styles = {
    bar: {
        background: '#A8320D',
    },
    apps: {
        flex: 1,
    }
};

const MyAppBar = withStyles(styles)(({ classes, children,
    userMenu, translate, ...props }) => (
        <AppBar {...props}
            className={classes.bar}
            title="Admin"
            userMenu={<MyUserMenu />}
        >
            <Typography
                variant="title"
                color="inherit"
                className={classes.apps}
            >
                <p>{translate('main.title', { _: 'Admin' })}</p>
            </Typography>
            <Menu />
        </AppBar>
    ));

export default translate(MyAppBar);