import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { changeLocale } from 'react-admin';
import { connect } from 'react-redux';
import auth from '../auth';
import { storeUserProfile } from '../store/auth/actions';
import LandingPage from './LandingPage';

class Callback extends Component {
    static propTypes = {
        redirect: PropTypes.bool,
        storeUserProfile: PropTypes.func,
        changeLanguage: PropTypes.func,
    };
    componentWillMount() {
        if (!this.props.redirect) {
            this.handleAuthentication()
        }
    }

    render() {
        return this.props.redirect ? <LandingPage /> : null
    }

    async handleAuthentication() {
        await auth.handleAuthentication()
        const profile = await auth.getUserProfile()

        if (profile['https://gemsotec.com/language']) {
            this.props.changeLanguage(profile['https://gemsotec.com/language'])
        } else {
            this.props.changeLanguage('en')
        }

        this.props.storeUserProfile(profile)
    }
}

const mapStateToProps = (state) => ({
    redirect: !!state.auth.profile,
})

const mapDispatchToProps = (dispatch) => ({
    storeUserProfile: (profile) => dispatch(storeUserProfile(profile)),
    changeLanguage: (code) => (dispatch(changeLocale(code)))
})

export default connect(mapStateToProps, mapDispatchToProps)(Callback)