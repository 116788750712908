import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Datagrid, DeleteButton, List, TextField } from 'react-admin'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import auth from '../auth'
import UserFilter from './UserFilter'
import UserListActions from './UserListActions'



class UserList extends Component {
    static propTypes = {
        accessible: PropTypes.bool,
    }

    render() {
        const { accessible, dispatch, ...props } = this.props
        if (!accessible) {
            sessionStorage.landingUrl = this.props.history.location.pathname
            return (<Redirect to="/login" />)
        }
        else {
            return (
                <div>
                    {auth.userHasScopes(["read:users"]) &&
                        <List {...props} sort={{ field: 'email', order: 'ASC' }} exporter={false} filters={<UserFilter />} actions={<UserListActions />}>
                            <Datagrid rowClick="edit" >
                                <TextField source="email" />
                                <TextField source="connection" />
                                {auth.userHasScopes(["delete:users"]) &&
                                    <DeleteButton />
                                }
                            </Datagrid>
                        </List>
                    }
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return ({
        accessible: !!state.auth.profile,
    })
}

export default connect(mapStateToProps)(UserList)