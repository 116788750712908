import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'
import { Edit, FormDataConsumer, ReferenceArrayInput, SelectArrayInput, SelectField, SelectInput, SimpleForm, TextField, TextInput } from 'react-admin'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Field } from 'redux-form'
import auth from '../auth'
import CategoryFilterField from '../categories/CategoryFilterField'

const NameField = ({ record }) => (
    <Tooltip title={record.description} key={record.id} placement="bottom">
        <div>{record.name}</div>
    </Tooltip>
)

const UserEdit = ({ accessible, dispatch, recordLiveValues, roles, ...props }) => {
    if (!accessible) {
        sessionStorage.landingUrl = props.history.location.pathname
        return (<Redirect to="/login" />)
    }
    else {
        if (auth.userHasScopes(["update:users"])) {
            return (
                <Edit {...props} >
                    <SimpleForm >
                        <SelectField
                            disabled={true}
                            source="loginType"
                            choices={[
                                { id: 'Username-Password-Authentication', name: 'Email & Password' },
                                { id: 'google-oauth2', name: 'Google' },
                                { id: 'microsoft', name: 'Microsoft' },
                            ]}
                            defaultValue='Username-Password-Authentication' />
                        <TextField source="email" />
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                formData.loginType === 'Username-Password-Authentication' &&
                                <div>
                                    <TextInput source="firstName" resettable />
                                    <br />
                                    <TextInput source="familyName" resettable />
                                </div>
                            }
                        </FormDataConsumer>
                        <ReferenceArrayInput source="userRoles" reference="roles" >
                            <SelectArrayInput optionText={<NameField />} translateChoice={false} />
                        </ReferenceArrayInput>
                        <SelectInput
                            source="language"
                            choices={[
                                { id: 'en', name: 'main.english' },
                                { id: 'nl', name: 'main.dutch' },
                                { id: 'fr', name: 'main.french' },
                                { id: 'es', name: 'main.spanish' },
                                { id: 'da', name: 'main.danish' },
                                { id: 'it', name: 'main.italian' },
                                { id: 'ro', name: 'main.romanian' }
                            ]}
                            defaultValue={'en'} />
                        <Field name="category" component={CategoryFilterField} />
                    </SimpleForm>
                </Edit>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    accessible: !!state.auth.profile,
})

export default connect(mapStateToProps)(UserEdit)