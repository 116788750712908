import adminDataProvider from './adminDataProvider';
import brandingDataProvider from './brandingDataProvider';
import goroundDataProvider from './goroundDataProvider';
import prioritiesDataProvider from './prioritiesDataProvider';

export default (type, resource, params) => {
    if (resource === 'categories') {
        return goroundDataProvider(type, resource, params);
    }
    if  (resource === 'priorities') {
        return prioritiesDataProvider(type, resource, params);
    }
    if  (resource === 'branding' ) {
        return brandingDataProvider(type, resource, params);
    }
    return adminDataProvider(type, resource, params);
}