
import React from 'react';
import { Edit, SaveButton, SimpleForm, Toolbar, translate } from 'react-admin';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import auth from '../auth';
import { ColorPicker } from './ColorPicker';
import CustomImage from './CustomImage';


export const CustomToolbar = (props) => (
    <Toolbar {...props} >
        <SaveButton
            redirect={false}
            label="ra.action.save"
        />
    </Toolbar>
);


const BrandingEdit = ({ accessible, dispatch, recordLiveValues, roles, translate, ...props }) => {
    if (!accessible) {
        sessionStorage.landingUrl = props.history.location.pathname
        return (<Redirect to="/login" />)
    }
    if (auth.userHasScopes(['update:branding'])) {
        return (
            <Edit id={`${Math.random()}`} {...props}>
                <SimpleForm toolbar={<CustomToolbar />}>
                    <CustomImage source="logo"
                        placeholder={<p>{translate('main.drop_image', { _: "Drop your image here" })}</p>} />
                    <ColorPicker />
                </SimpleForm>
            </Edit>
        )
    }
    return (<div></div>)
}

const mapStateToProps = (state) => ({
    accessible: !!state.auth.profile,
})

export default translate(connect(mapStateToProps)(BrandingEdit))