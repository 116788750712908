export const da = {
    main: {
        language: "Dansk",
        searchbar: 'Søg (min. 3 tegn)',
        title: "Admin",
        logout: "Log ud",
        english: "Engelsk",
        dutch: "Hollandske",
        french: "Fransk",
        spanish: "Spansk",
        danish: "Dansk",
        italian: "Italiensk",
        romanian: "Rumænsk",
        drop_image: "Slip dit billede her",
    },
    ra: {
        tree: {
            add_child_node: 'Tilføj underkategori',
            add_node_after: 'Tilføj kategori efter',
            add_node_before: 'Tilføj kategori for',
            close: 'Tæt',
            expand: 'At åbne',
        },
    },
    resources: {
        categories: {
            name: "Kategori |||| Kategorier",
            fields: {
                name: 'Kategorinavn',
            }
        },
        users: {
            name: "Bruger |||| Brugere",
            fields: {
                category: 'Kategori',
                email: 'E-mail',
                familyName: 'Familie navn',
                firstName: 'Fornavn',
                language: 'Sprog',
                userRoles: 'Roller',
            }
        },
        priorities: {
            name: "Prioritet |||| Prioriteter",
        },
        branding: {
            name: "Logo",
        },
    }
}