
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React from 'react';
import { Edit, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Field } from 'redux-form';
import auth from '../auth';

export const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            redirect={false}
            label="ra.action.save"
        />
    </Toolbar>
);

const Input = ({ placeholder, ...props }) => <TextInput {...props} label={props?.input?.value || props?.meta?.active ? "" : placeholder} resettable />

const PriorityEdit = ({ accessible, dispatch, recordLiveValues, roles, ...props }) => {
    if (!accessible) {
        sessionStorage.landingUrl = props.history.location.pathname
        return (<Redirect to="/login" />)
    }
    if (auth.userHasScopes(['update:priorities'])) {
        return (
            <Edit id={`${Math.random()}`} {...props} >
                <SimpleForm toolbar={<CustomToolbar />} >
                    <div style={{ display: 'flex', flexDirection: "row" }}>
                        <span style={{ display: 'flex', alignItems: "flex-end", margin: "10px" }}><ArrowUpwardIcon style={{ color: "red" }} /></span>
                        <Field component={Input} name="highPriorityLabel" placeholder="1" />
                    </div>

                    <div style={{ flex: 1, display: 'flex' }}>
                        <span style={{ display: 'flex', alignItems: "flex-end", margin: "10px" }}><ArrowUpwardIcon style={{ color: "orange" }} /></span>
                        <Field component={Input} name="mediumPriorityLabel" placeholder="2" />
                    </div>

                    <div style={{ flex: 3, display: 'flex' }}>
                        <span style={{ display: 'flex', alignItems: "flex-end", margin: "10px" }}><ArrowDownward style={{ color: "green" }} /></span>
                        <Field component={Input} name="lowPriorityLabel" placeholder="3" />
                    </div>
                </SimpleForm>
            </Edit>
        )
    }
    return (<div></div>)
}

const mapStateToProps = (state) => ({
    accessible: !!state.auth.profile,
})

export default connect(mapStateToProps)(PriorityEdit)