import MergeType from '@material-ui/icons/MergeType';
import CategoryAdd from './CategoryAdd';
import CategoryEdit from './CategoryEdit';
import CategoryList from "./CategoryList";

export default {
    create: CategoryAdd,
    edit: CategoryEdit,
    icon: MergeType,
    list: CategoryList
}