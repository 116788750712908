import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Create, SaveButton, SimpleForm, TextInput, Toolbar, translate } from 'react-admin';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import auth from '../auth';

const styles = {
    button: {
        marginTop: '1em'
    }
};

export const CustomToolbarAdd = props => (
    <Toolbar {...props} >
        <SaveButton
            label="ra.action.add"
            submitOnEnter={false}
        />
    </Toolbar>
);


class CategoryAdd extends Component {
    static propTypes = {
        accessible: PropTypes.bool,

    };
    render() {
        const { accessible, dispatch, roles, classes, record, ...props } = this.props
        if (!accessible) {
            sessionStorage.landingUrl = props.history.location.pathname
            return (<Redirect to="/login" />)
        }
        if (auth.userHasScopes(['create:categories'])) {
            return (
                <Create {...props}>
                    <SimpleForm redirect="list" toolbar={<CustomToolbarAdd />}>
                        <TextInput source="name" resettable />
                    </SimpleForm>
                </Create>
            )
        }
        return (<div></div>)
    }
}

const mapStateToProps = (state) => ({
    accessible: !!state.auth.profile,
})

export default withStyles(styles)(translate(connect(mapStateToProps)(CategoryAdd)))