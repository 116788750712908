// domain translations
import spanishMessages from 'aor-language-spanish';
import { createBrowserHistory as createHistory } from 'history';
import danishMessages from 'ra-language-danish';
import dutchMessages from 'ra-language-dutch';
import englishMessages from 'ra-language-english';
import frenchMessages from 'ra-language-french';
import italianMessages from 'ra-language-italian';
import romanianMessages from "ra-language-romanian";
import treeEnglishMessages from 'ra-tree-language-english';
import treeFrenchMessages from 'ra-tree-language-french';
import { reducer as tree } from 'ra-tree-ui-materialui';
import React, { Component } from 'react';
import { Admin, mergeTranslations, Resource } from 'react-admin';
import './App.css';
import Login from './auth/Login';
import branding from './branding';
import invitations from './invitations';
import categories from './categories';
import customRoutes from './customRoutes';
import dataProvider from './dataProvider';
import * as owndics from './dic';
import MyLayout from './MyLayout';
import priorities from './priorities';
import authReducer from './store/auth';
import users from './users';

const history = createHistory()

const messages = {
  'nl': mergeTranslations(dutchMessages, owndics.nl),
  'en': mergeTranslations(englishMessages, treeEnglishMessages, owndics.en),
  'es': mergeTranslations(spanishMessages, owndics.es),
  'fr': mergeTranslations(frenchMessages, treeFrenchMessages, owndics.fr),
  'it': mergeTranslations(italianMessages, owndics.it),
  'da': mergeTranslations(danishMessages, owndics.da),
  'ro': mergeTranslations(romanianMessages, owndics.ro)
};

const i18nProvider = locale => messages[locale];

class App extends Component {
  render() {
    return (
      <Admin
        locale="en"
        i18nProvider={i18nProvider}
        loginPage={Login}
        customReducers={{ auth: authReducer, tree }}
        customRoutes={customRoutes}
        appLayout={MyLayout}
        history={history}
        dataProvider={dataProvider}
      >
        <Resource name='users' {...users} />
        <Resource name="invitations" {...invitations} />
        <Resource name="roles" />
        <Resource name="meta" />
        <Resource name="categories" {...categories} />
        <Resource name="priorities" {...priorities} />
        <Resource name="branding" {...branding} />
      </Admin>
    )
  }
}

export default App;
