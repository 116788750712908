import 'rc-tree-select/assets/index.css';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import CategoryFilter from './CategoryFilter';

interface Props extends WrappedFieldProps {
    resource: string;
}

const CategoryFilterField = ({ input: { name, onChange, value }, resource }: Props) => {
    return (
        <div style={{ marginTop: 16, marginBottom: 8 }}>
            <CategoryFilter
                label={`resources.${resource}.fields.${name}`}
                onChange={(newValue) => onChange(newValue)}
                value={value}
            />
        </div>
    )
};

export default CategoryFilterField