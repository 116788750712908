export const en = {
    main: {
        language: "English",
        searchbar: 'Search (min. 3 characters)',
        title: "Admin",
        logout: "Logout",
        english: "English",
        dutch: "Dutch",
        french: "French",
        spanish: "Spanish",
        danish: "Dansk",
        italian: "Italian",
        romanian: "Romanian",
        drop_image: "Drop your image here",
    },
    resources: {
        categories: {
            name: "Category |||| Categories",
            fields: {
                name: 'Category name',
            },
        },
        users: {
            name: "User |||| Users",
            fields: {
                category: 'Category',
                email: 'Email',
                familyName: 'Family name',
                firstName: 'First name',
                language: 'Language',
                userRoles: 'Roles',
            }
        },
        priorities: {
            name: "Priority |||| Priorities",
        },
        branding: {
            name: "Branding"
        },
    }
}