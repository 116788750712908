import restProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'react-admin';
import auth from '../auth';
import fakeTreeDataProvider from './fakeTreeDataProvider';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    options.headers.set('Authorization', `Bearer ${auth.accessToken}`)
    return fetchUtils.fetchJson(url, options);
}

const dataProvider = (type, resource, params) => {
    if (!auth.apiUrl) {
        return fakeTreeDataProvider(type, resource, params);
    }
    const apiUrl = `${auth.apiUrl}/api`
    switch (type) {
        case 'GET_TREE_ROOT_NODES':
            return httpClient(`${apiUrl}/${resource}?parentId=root`).then(response => ({ data: response.json }));
        case 'GET_TREE_CHILDREN_NODES':
            return httpClient(`${apiUrl}/${resource}?parentId=${params.id}`).then(response => ({ data: response.json }));
        default:
            const baseProvider = restProvider(apiUrl, httpClient);
            return baseProvider(type, resource, params);
    }
}

export default dataProvider;