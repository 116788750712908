import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Datagrid, DeleteButton, List, TextField, DateField } from 'react-admin'
import auth from '../auth'


class InvitationList extends Component {
    static propTypes = {
        accessible: PropTypes.bool,
    }


    render() {
        const { accessible, dispatch, ...props } = this.props
        if (!accessible) {
            sessionStorage.landingUrl = this.props.history.location.pathname
            return (<Redirect to="/login" />)
        }
        else {
            return (
                <div>
                    {auth.userHasScopes(["read:users"]) &&
                        <List {...props} sort={{ field: 'invitee.email', order: 'ASC' }}
                            exporter={false}>
                            <Datagrid rowClick="edit" >
                                <TextField source="invitee.email" label="To" />

                                <DateField source="created_at" locales="nl-BE" showTime options={{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: false
                                }} />

                                <DateField source="expires_at" label="Expire at" locales="nl-BE" showTime options={{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: false
                                }} />

                                <DeleteButton />
                            </Datagrid>
                        </List>
                    }
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return ({
        accessible: !!state.auth.profile,
    })
}

export default connect(mapStateToProps)(InvitationList)