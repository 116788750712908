export const fr = {
    main: {
        language: "Français",
        searchbar: 'Chercher (3 caractères min.)',
        title: "Admin",
        logout: "Déconnectez",
        english: "anglais",
        dutch: "néerlandais",
        french: "français",
        spanish: "espagnol",
        danish: "danois",
        italian: "italien",
        romanian: "roumain"
    },
    ra: {
        tree: {
            add_child_node: 'Ajouter une sous-catégorie',
            add_node_after: 'Ajouter une catégorie après',
            add_node_before: 'Ajouter une catégorie avant',
            close: 'Fermer',
            expand: 'Ouvrir',
        },
    },
    resources: {
        categories: {
            name: "Catégorie |||| Catégories",
            fields: {
                name: 'Nom de catégorie',
            }
        },
        users: {
            name: "Utilisateur |||| Utilisateurs",
            fields: {
                category: 'Catégorie',
                email: 'E-mail',
                familyName: 'Nom',
                firstName: 'Prénom',
                language: 'Langue',
                userRoles: 'Rôles',
            }
        },
        priorities: {
            name: "Priorité |||| Priorités",
        },
        branding: {
            name: "Logo"
        },
    }
}