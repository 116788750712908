import { Auth0DecodedHash, WebAuth } from 'auth0-js';

const scopes = 'openid profile user_metadata create:users read:users update:users delete:users read:roles read:categories update:categories delete:categories create:categories read:priorities update:priorities update:branding'

const urlParams = new URLSearchParams(window.location.search)
const invitation = urlParams?.get?.('invitation')
const organization = urlParams?.get?.('organization')

const auth0 = new WebAuth({
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    clientID: process.env.REACT_APP_AUTH0_CLIENTID || '',
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    redirectUri: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/callback`,
    responseType: 'token id_token',
    scope: scopes,
    ...(invitation ? {invitation} : {}),
    ...(organization ? {organization} : {})
})

class Auth {
    accessToken?: string
    apiUrl?: string
    expiresAt = (process.env.NODE_ENV === 'production' || process.env.REACT_APP_FORCE_AUTH === 'true') ? 0 : Number.MAX_SAFE_INTEGER
    idToken?: string
    scopes = ((process.env.NODE_ENV === 'production' || process.env.REACT_APP_FORCE_AUTH === 'true') ? '' : scopes).split(' ')
    tokenRenewalTimeout?: NodeJS.Timeout

    login() {
        auth0.authorize()
    }

    handleAuthentication() {
        return new Promise(resolve => auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult)
                resolve()
            } else if (err) {
                this.logout()
            }
        }))
    }


    userHasScopes(scopes: string[]) {
        if (this.isAuthenticated()) {
            return scopes.every(scope => this.scopes.includes(scope));
        } else {
            return false
        }
    }

    getUserProfile() {
        return new Promise((resolve, reject) => {
            if (!this.accessToken) {
                reject("Not authenticated!")
            } else {
                auth0.client.userInfo(this.accessToken, (err, profile) => {
                    if (profile) {
                        this.apiUrl = process.env.REACT_APP_GOROUND_BACKEND_URL || (profile as any)['https://gemsotec.com/apiv3'] as string
                        console.log(`set api url to ${this.apiUrl}`)
                        resolve(profile)
                    } else {
                        reject(err)
                    }
                })
            }
        })
    }

    setSession(authResult: Auth0DecodedHash) {
        // Set isLoggedIn flag in localStorage
        localStorage.setItem('isLoggedIn', 'true')

        // Set the time that the access token will expire at
        const expiresAt = authResult.expiresIn ? (authResult.expiresIn * 1000) + new Date().getTime() : 0
        this.accessToken = authResult.accessToken
        this.idToken = authResult.idToken
        this.expiresAt = expiresAt

        // Set the users scopes
        this.scopes = (authResult.scope || '').split(' ');

        this.scheduleRenewal()
    }

    renewSession() {
        return new Promise(resolve => auth0.checkSession({}, (err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult)
                resolve()
            } else if (err) {
                this.logout()
            }
        }))
    }

    scheduleRenewal() {
        if (this.tokenRenewalTimeout) {
            clearTimeout(this.tokenRenewalTimeout)
        }
        const expiresAt = this.expiresAt
        const timeout = expiresAt - Date.now()
        if (timeout > 0) {
            this.tokenRenewalTimeout = setTimeout(() => {
                this.renewSession()
            }, timeout)
        }
    }

    logout() {
        // Remove tokens and expiry time
        this.accessToken = undefined
        this.idToken = undefined
        this.expiresAt = 0
        if (this.tokenRenewalTimeout) {
            clearTimeout(this.tokenRenewalTimeout)
        }

        // Remove isLoggedIn flag from localStorage
        localStorage.removeItem('isLoggedIn')

        auth0.logout({
            clientID: process.env.REACT_APP_AUTH0_CLIENTID,
            returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI,
        })
    }

    isAuthenticated() {
        // Check whether the current time is past the
        // access token's expiry time
        const expiresAt = this.expiresAt
        return new Date().getTime() < expiresAt
    }
}

export default new Auth()