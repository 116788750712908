
import React from 'react';
import CustomFileInput from './CustomFileInput';

const CustomImage = ({...props }) => {
        return (
            <CustomFileInput  {...props}  />
        )
}

export default CustomImage;