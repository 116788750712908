import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import { changeLocale as changeLocaleAction, translate, UPDATE } from 'react-admin';
import { connect } from 'react-redux';
import dataProvider from './dataProvider';



class LocaleSwitcher extends Component {
    switchLng = (code) => {
        this.props.changeLocale(code)
        this.updateLocale(code)
    };


    updateLocale = (lngcode) => {
        if (this.props.profile["sub"]) {
            dataProvider(UPDATE, 'meta', { id: this.props.profile["sub"], data: { language: lngcode } })
        }
    }


    render() {
        const { translate, locale } = this.props;
        return (
            <div>
                <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", alignItems: "flex-end", color: "black", padding: 10 }}>{translate('main.language', { _: "Untranslated Language" })}<br /></div>
                <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", alignItems: "flex-end", padding: 10 }}>
                    <Button style={{ backgroundColor: locale === "en" ? "blue" : "white", color: locale === "en" ? "white" : "black" }} onClick={() => this.switchLng("en")}>en</Button>
                    <Button style={{ backgroundColor: locale === "nl" ? "blue" : "white", color: locale === "nl" ? "white" : "black" }} onClick={() => this.switchLng("nl")}>nl</Button>
                    <Button style={{ backgroundColor: locale === "fr" ? "blue" : "white", color: locale === "fr" ? "white" : "black" }} onClick={() => this.switchLng("fr")}>fr</Button>
                    <Button style={{ backgroundColor: locale === "es" ? "blue" : "white", color: locale === "es" ? "white" : "black" }} onClick={() => this.switchLng("es")}>es</Button>
                    <Button style={{ backgroundColor: locale === "da" ? "blue" : "white", color: locale === "da" ? "white" : "black" }} onClick={() => this.switchLng("da")}>da</Button>
                    <Button style={{ backgroundColor: locale === "it" ? "blue" : "white", color: locale === "it" ? "white" : "black" }} onClick={() => this.switchLng("it")}>it</Button>
                    <Button style={{ backgroundColor: locale === "ro" ? "blue" : "white", color: locale === "ro" ? "white" : "black" }} onClick={() => this.switchLng("ro")}>ro</Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.auth.profile,
})


export default translate(connect(mapStateToProps, { changeLocale: changeLocaleAction })(LocaleSwitcher));