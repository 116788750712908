import { fetchUtils, GET_ONE, UPDATE } from 'react-admin';
import auth from '../auth';
import fakeDataProvider from './fakeDataProvider';

const httpClient = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    options.headers.set('Authorization', `Bearer ${auth.accessToken}`)
    return fetchUtils.fetchJson(url, options);
}



const prioritiesDataProvider = async (type, resource, params) => {
    if (!auth.apiUrl) {
        return fakeDataProvider(type, "priorities", params);
    }
    const apiUrl = `${auth.apiUrl}/api`

    switch (type) {
        case GET_ONE:
            const result = await httpClient(`${apiUrl}/priorities`).then(response => response.json)
            return { data: { id: params.id, highPriorityLabel: result[0], mediumPriorityLabel: result[1], lowPriorityLabel: result[2] } }

        case UPDATE:
            var noLabels = (params.data.highPriorityLabel === "" && params.data.mediumPriorityLabel === "" && params.data.lowPriorityLabel === "")
            var priorityLabels = []
            noLabels ? priorityLabels.push("1", "2", "3") : priorityLabels.push(params.data.highPriorityLabel || "", params.data.mediumPriorityLabel || "", params.data.lowPriorityLabel || "")
            return await fetch(`${apiUrl}/priorities`, {
                method: 'put',
                body: JSON.stringify(priorityLabels),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`
                },
            })
                .then(response => response.json).then(data => ({ data: { id: params.id, highPriorityLabel: data[0], mediumPriorityLabel: data[1], lowPriorityLabel: data[2] } }))
                .catch(error => {
                    console.error('Error during priority labels updating:', error);
                });
        default:
            throw new Error(`Not implemented`)
    }
}

export default prioritiesDataProvider;