import fakeDataProvider from './fakeDataProvider';

export default (type, resource, params) => {
    switch (type) {
        case 'GET_TREE_ROOT_NODES':
            const allCategories = fakeDataProvider('GET_LIST', resource, { pagination: { page: 1, perPage: 1000000 }, sort: { field: 'id', order: 'ASC' } });
            return allCategories.then(all => ({
                data: all.data.filter(cat => !cat.parentId),
            }));
        case 'GET_TREE_CHILDREN_NODES':
            return fakeDataProvider('GET_MANY_REFERENCE', resource, { pagination: { page: 1, perPage: 1000000 }, sort: { field: 'id', order: 'ASC' }, target: 'parentId', id: params.id });
        default:
            return fakeDataProvider(type, resource, params);
    }
}