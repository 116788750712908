import { Button } from '@material-ui/core';
import ExitToApp from '@material-ui/icons/ExitToApp';
import React, { Component, Fragment } from 'react';
import { translate } from 'react-admin';
import { connect } from 'react-redux';
import auth from './auth';
import LocaleSwitcher from './LocaleSwitcher';

class Log extends Component {
    render() {
        return (
            <Fragment>
                <LocaleSwitcher />
                <Button color="inherit" aria-label="Log out" onClick={this.handleLogout} style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
                    <ExitToApp /><span style={{ paddingLeft: 5 }}>{this.props.translate('main.logout', { _: 'Logout' })}</span>
                </Button>
            </Fragment>
        )
    }

    handleLogout = () => {
        if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_FORCE_AUTH === 'true') {
            auth.logout()
        }
    }
}

const mapStateToProps = (state) => ({
    profile: state.auth.profile,
})

export default translate((connect(mapStateToProps)(Log)))