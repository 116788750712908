import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Apps from '@material-ui/icons/Apps';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Person from '@material-ui/icons/Person';
import React, { Component } from 'react';
import BlockMenu from './BlockMenu';

class Menu extends Component {
    state = {
        open: false,
    };

    anchorEl = this.anchorEl
    render() {
        const { open } = this.state;
        return (
            <div>
                <IconButton
                    buttonRef={node => {
                        this.anchorEl = node;
                    }}
                    // aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="false"
                    onClick={this.handleToggle}
                    color="inherit"
                    disableRipple={true}
                >
                    <Apps />
                </IconButton>
                <Popper open={open}
                    anchorEl={this.anchorEl}
                    transition disablePortal
                    style={{ marginRight: '20px' }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', marginTop: '20px' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList>
                                        <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap" style={{ paddingRight: '10px', paddingLeft: '10px', display: 'flex' }} >
                                            <BlockMenu backgroundColor='#411a64' value="Management" href={process.env.REACT_APP_GOROUND_URL} icon={CheckCircle} />
                                            <BlockMenu backgroundColor='#A8320D' value="Admin" href={process.env.REACT_APP_AUTH0_REDIRECT_URI} icon={Person} />
                                        </Grid>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        )
    }

    handleToggle = () => {
        this.setState(state => ({ open: !state.open }))
    };

    handleClose = (event) => {
        const anchorEl = this.anchorEl
        if (anchorEl && anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ open: false });
    }
}

export default Menu;