import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Field } from 'redux-form';


const renderColorPicker = ({ input, label, meta: { touched, error }, ...custom }) => (
    <TextField
        label={label}
        error={!!(touched && error)}
        helperText={touched && error}
        {...input}
        {...custom}

    />
);

export const ColorPicker = (props) => {
    const [textColor, setTextColor] = useState(props.record.colorText)
    return (
        <div style={{ display: "flex", alignItems: "end", }}>
            <div style={{ marginRight: '5px', backgroundColor: textColor ? textColor : "", width: "20px", height: "20px" }}></div>
            <Field
                style={{ width: "37%" }}
                onChange={(e) => { setTextColor(e.currentTarget.value) }}
                name="colorText"
                component={renderColorPicker}
                label="Color in hexadecimal start with #"
            />
        </div>
    )
}
