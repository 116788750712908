export const it = {
    main: {
        language: "Inglese",
        searchbar: 'Cerca (minimo 3 caratteri)',
        title: "Admin",
        logout: "disconnettersi",
        english: "Inglese",
        dutch: "Olandese",
        french: "Francese",
        spanish: "Spagnolo",
        danish: "Danese",
        italian: "Inglese",
        romanian: "Rumeno",
        drop_image: "Trascina qui la tua immagine",
    },
    ra: {
        tree: {
            add_child_node: 'Aggiungi sottocategoria',
            add_node_after: 'Aggiungi categoria dopo',
            add_node_before: 'Aggiungi categoria per',
            close: 'Vicino',
            expand: 'Aprire',
        },
    },
    resources: {
        categories: {
            name: "Categoria |||| Categorie",
            fields: {
                name: 'Nome della categoria',
            }
        },
        users: {
            name: "Utente  |||| Utenti",
            fields: {
                category: 'Categoria',
                email: 'E-mail',
                familyName: 'Cognome',
                firstName: 'Nome di battesimo',
                language: 'Linguaggio',
                userRoles: 'Ruoli',
            }
        },
        priorities: {
            name: "Priorità |||| Priorità",
        },
        branding: {
            name: "Logo"
        },
    }
}