import Person from '@material-ui/icons/Person';
import UserAdd from './UserAdd';
import UserEdit from './UserEdit';
import UserList from "./UserList";

export default {
    create: UserAdd,
    edit: UserEdit,
    icon: Person,
    list: UserList,
}