
import React from 'react';
import { Edit, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import auth from '../auth';

const SaveOnlyToolbar = props => (<Toolbar {...props}><SaveButton /></Toolbar>)

const CategoryEdit = ({ accessible, dispatch, recordLiveValues, roles, ...props }) => {
    if (!accessible) {
        sessionStorage.landingUrl = props.history.location.pathname
        return (<Redirect to="/login" />)
    }
    if (auth.userHasScopes(['update:categories'])) {
        const toolbar = !auth.userHasScopes(['delete:categories']) ? <SaveOnlyToolbar /> : undefined
        return (
            <Edit {...props} >
                <SimpleForm toolbar={toolbar}>
                    <TextInput source="name" resettable />
                </SimpleForm>
            </Edit>
        )
    }
    return (<div></div>)
}

const mapStateToProps = (state) => ({
    accessible: !!state.auth.profile,
})

export default connect(mapStateToProps)(CategoryEdit)