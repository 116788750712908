import moment from 'moment';
import { getType } from 'typesafe-actions';
import * as actions from './actions';


const initialState = (process.env.NODE_ENV === 'production' || process.env.REACT_APP_FORCE_AUTH === 'true') ?
    {
    } :
    {
        profile: {
            clientID: 'none',
            created_at: moment().format(),
            identities: [],
            name: 'fake@gemsotec.com',
            nickname: 'fake',
            picture: '',
            sub: '',
            updated_at: moment().format(),
            user_id: 'none',
        }
    }

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.storeUserProfile): {
            return {
                ...state,
                profile: action.payload,
            }
        }
        default:
            return state
    }
}