import { TextInput } from 'ra-ui-materialui/lib/input';
import { Filter } from 'ra-ui-materialui/lib/list';
import React from 'react';
import { translate } from 'react-admin';

export default translate((props) => (
    <Filter {...props}>
        <TextInput label={props.translate('main.searchbar', { _: "Search (min 3 characters)" })} source="email" alwaysOn />
    </Filter>
))
