
import { Avatar } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import React from 'react';
import { MenuItemLink, UserMenu } from 'react-admin';
import { connect } from 'react-redux';
import Logout from './Logout';


class MyUserMenu extends React.Component {
    static defaultProps = {
        label: 'ra.auth.user_menu',
        icon: <AccountCircle />,
        logout: <ExitToApp />,
    };

    render() {
        return (
            <div>
                <UserMenu {...this.props} label="user info" icon={<Avatar src={this.props.profile && this.props.profile.picture} />} logout={<Logout />} style={{ bottom: 0, position: 'absolute' }}>
                    <MenuItemLink
                        to="/"
                        primaryText={this.props.profile && this.props.profile.name}
                        leftIcon={<Avatar src={this.props.profile && this.props.profile.picture} />}
                        style={{ padding: 15 }}
                    />
                </UserMenu>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.auth.profile,
})

export default (connect(mapStateToProps)(MyUserMenu))
