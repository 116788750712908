export const es = {
    main: {
        language: "español",
        searchbar: 'Buscar (mín. 3 caracteres)',
        title: "Admin",
        logout: "Cerrar sesión",
        english: "inglés",
        dutch: "holandés",
        french: "francés",
        spanish: "español",
        danish: "danés",
        italian: "italiano",
        romanian: "rumano",
        drop_image: "Suelta tu imagen aquí",
    },
    ra: {
        tree: {
            add_child_node: 'Añadir subcategoría',
            add_node_after: 'Añadir categoría después',
            add_node_before: 'Añadir categoría para',
            close: 'Cerrar',
            expand: 'Abrir',
        },
    },
    resources: {
        categories: {
            name: "Categoria |||| Categorias",
            fields: {
                name: 'Nombre de categoria',
            }
        },
        users: {
            name: "Usuario |||| Usuarios",
            fields: {
                category: 'Categoria',
                email: 'Correo electrónico',
                familyName: 'Apellido',
                firstName: 'Nombre',
                language: 'Idioma',
                userRoles: 'Roles',
            }
        },
        priorities: {
            name: "Prioridad |||| Prioridades",
        },
        branding: {
            name: "Logo"
        },
    }
}