import { AddChildNodeMenuItem, DeleteMenuItem, EditMenuItem, TreeNodeActions, TreeNodeActionsMenu } from 'ra-tree-ui-materialui';
import React from 'react';
import auth from '../auth';

export const CategoryNodeActions = props => {
    const actions = []
    if (auth.userHasScopes(['update:categories'])) {
        actions.push(<EditMenuItem />)
    }
    if (auth.userHasScopes(['create:categories'])) {
        actions.push(<AddChildNodeMenuItem />)
    }
    if (auth.userHasScopes(['delete:categories'])) {
        actions.push(<DeleteMenuItem />)
    }
    if (!actions.length) {
        return null
    }
    return (
        <TreeNodeActions {...props}>
            <TreeNodeActionsMenu >
                {actions}
            </TreeNodeActionsMenu>
        </TreeNodeActions>
    )
}