export const ro = {
    main: {
        language: "Română",
        searchbar: 'Căutare (min. 3 caractere)',
        title: "Admin",
        logout: "Logout",
        english: "Engleză",
        dutch: "Olandeză",
        french: "limba franceza",
        spanish: "Spaniolă",
        danish: "Danez",
        italian: "Italiană",
        romanian: "Română",
        drop_image: "Lasă-ți imaginea aici",
    },
    ra: {
        tree: {
            add_child_node: 'Adăugați o subcategorie',
            add_node_after: 'Adăugați categoria după',
            add_node_before: 'Adăugați o categorie pentru',
            close: 'Închide',
            expand: 'A deschide',
        },
    },
    resources: {
        categories: {
            name: "Categorie |||| Categorii",
            fields: {
                name: 'Numele categoriei',
            },
        },
        users: {
            name: "Utilizator |||| Utilizatori",
            fields: {
                category: 'Categorie',
                email: 'E-mail',
                familyName: 'Nume de familie',
                firstName: 'Nume',
                language: 'Limba',
                userRoles: 'Roluri',
            }
        },
        priorities: {
            name: "Prioritate |||| Priorități",
        },
        branding: {
            name: "Siglă"
        },
    }
}