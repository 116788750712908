import React, { useMemo, useState, useEffect, useCallback } from 'react';
import TreeViewSelect, { listItem } from "./TreeViewSelect";
import dataProvider from '../dataProvider';

interface DataItem {
    id: string;
    name: string;
    parentId?: string;
}

export interface PathItem {
    id: string;
    name: string;
}

interface OwnProps {
    label: string;
    onChange: (value: PathItem[]) => any;
    value: PathItem[]
}

type Props = OwnProps;

const getParentData = async (parentId: string) => {
    const { data } = await dataProvider('GET_TREE_CHILDREN_NODES', 'categories', { id: parentId })
    return data
}

const hasChildren = async (parentId: string) => {
    const categoryReq = await getParentData(parentId)
    if (!categoryReq || categoryReq.length > 0) {
        return false
    } else {
        return true
    }
}

const getFomattedData = async (categoryData: DataItem[]) => {
    const categoryDataFormat = categoryData.map(async (item) => ({
        id: item.id,
        title: item.name,
        value: item.id,
        pId: item.parentId || 'root',
        isLeaf: await hasChildren(item.id)
    }))

    const categoryDataFormatComplete: listItem[] = categoryDataFormat && await Promise.all(categoryDataFormat)

    if (!categoryDataFormatComplete) {
        return
    }
    return categoryDataFormatComplete
};

const convertToOrgData = (categoryFormattedData: listItem[]): PathItem[] | undefined => {

    if (!categoryFormattedData) {
        return undefined
    }
    const categoryDataOrgFormat = categoryFormattedData.map(item => {
        return ({
            id: item.id,
            name: item.title,
        })
    })

    if (!categoryDataOrgFormat) {
        return undefined
    }

    return categoryDataOrgFormat

};

const CategoryFilter = ({ label, onChange, value }: Props) => {
    const [data, setData] = useState<listItem[]>([])

    const selected = useMemo(() => {
        const formattedLabelPath = (value && value.map(item => item.name).join(' > ')) || ""
        const lastItem = value && value.slice(-1).pop()
        const lastItemValue = (lastItem && lastItem.id) || ""
        return { value: lastItemValue, label: formattedLabelPath }
    }, [value])

    useEffect(() => {
        const loadRootCategories = async () => {
            const categoriesData = await dataProvider('GET_TREE_ROOT_NODES', 'categories')
            const catgoriesDataFormat = categoriesData && await getFomattedData(categoriesData.data)
            catgoriesDataFormat && setData(catgoriesDataFormat)
        }
        loadRootCategories()
    }, [])

    const onLoadData = useCallback((treeNode: any) => {
        const id = treeNode && treeNode.props && treeNode.props.value

        return new Promise(async (resolve) => {
            const categoryData = await getParentData(id)
            const formattedData = categoryData && await getFomattedData(categoryData)
            const newData = formattedData && data.concat(formattedData)
            newData && setData(newData)
            resolve();
        });
    }, [data]);

    return (
        <TreeViewSelect
            label={label}
            list={data}
            onSelect={(val, path, org) => {
                const convertedData = convertToOrgData(org)
                convertedData && onChange && onChange(convertedData)
            }}
            onLoadData={onLoadData}
            selectedValue={selected && selected.value}
            selectedLabel={selected && selected.label}
            onClear={() => {
                onChange && onChange([])
            }}
        />
    )
};

export default CategoryFilter