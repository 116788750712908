import { createStyles, FormControl, InputLabel, Theme, withStyles, WithStyles } from "@material-ui/core";
//@ts-ignore
import TreeSelect from 'rc-tree-select';
import 'rc-tree-select/assets/index.css';
import React, { useCallback, useMemo, useState } from 'react';
//@ts-ignore
import { translate } from 'react-admin';

export interface listItem {
    id: string;
    title: string;
    value: string;
    pId: string;
    isLeaf?: boolean;
}

const getParent = (itemId: string, data: listItem[]): listItem[] => {
    const sel: listItem[] = [];
    const getLeaf = (id: string) => {
        const _data = data.filter(item => item.id === id)[0]
        if (!_data) {
            return
        }
        sel.push(_data);
        if (_data.pId !== "root") {
            getLeaf(_data.pId)
        }
    }
    getLeaf(itemId);
    return sel;
}

interface OwnProps {
    selectedValue?: string;
    selectedLabel?: string;
    list: listItem[];
    onSelect: (value: string, path: string, org: listItem[]) => any;
    onLoadData: (e: any) => unknown;
    onClear: () => unknown;
    label?: string;
}

const styles = (theme: Theme) => createStyles({
    '@global': {
        '.rc-tree-select-selection': {
            borderRadius: '0px',
            borderBottom: '1px solid #949494',
            border: 'none',
            backgroundColor: 'initial',
        },
        '.rc-tree-select-tree-node-selected': {
            backgroundColor: '#337ab7',
            border: '1px #337ab7 solid',
            opacity: 0.8
        },
        ".rc-tree-select-selection:hover": {
            borderColor: "none !important",
            boxShadow: "none !important"
        },
        '.rc-tree-select-enabled.rc-tree-select-focused .rc-tree-select-selection': {
            boxShadow: "none",
            borderColor: "none"
        },
    }
})

interface Props extends OwnProps, WithStyles<typeof styles> {
    translate: (key: string) => string;
}

const TreeViewSelectComponent = ({ label, list, onSelect, onLoadData, selectedValue = '', selectedLabel = '', onClear, translate }: Props) => {
    const [focused, setFocused] = useState(false);
    const lv = useMemo(() => ({ value: selectedValue, label: selectedLabel }), [selectedLabel, selectedValue]);

    const onChangeLV = useCallback((value: any) => {
        if (!value) {
            onClear()
            return;
        }
        const org = getParent(value.value, list).reverse()
        const path = org.map(item => item.title).join(' > ')
        onSelect(value.value, path, org)
    }, [list, onClear, onSelect]);

    return (
        <FormControl>
            <InputLabel shrink={!!selectedLabel || focused} focused={focused}>{label ? translate(label) : ''}</InputLabel>
            <TreeSelect style={{ width: 655, paddingTop: 18 }}
                transitionName="rc-tree-select-dropdown-slide-up"
                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                dropdownStyle={{ maxHeight: 200, overflow: 'auto', zIndex: 10000, borderRadius: '0px' }}
                loadData={onLoadData}
                showSearch={false}
                allowClear
                treeLine
                value={lv}
                labelInValue
                treeData={list}
                treeNodeFilterProp="title"
                filterTreeNode={false}
                onChange={onChangeLV}
                onDropdownVisibleChange={setFocused}
                treeDataSimpleMode={{
                    id: 'id',
                    rootPId: 'root',
                }}
            />
        </FormControl>
    );
};

const TreeViewSelect = withStyles(styles)(TreeViewSelectComponent);

export default translate(TreeViewSelect) as (props: OwnProps) => JSX.Element