import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Create, FormDataConsumer, ReferenceArrayInput, SelectArrayInput, SelectInput, SimpleForm, TextInput, email, required, translate } from 'react-admin'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Field } from 'redux-form'
import auth from '../auth'
import { CustomToolbarAdd } from '../categories/CategoryAdd'
import CategoryFilterField from '../categories/CategoryFilterField'

const validateRequiredEmail = [required(), email()]


const NameField = ({ record }) => (
    <Tooltip title={record.description} key={record.id} placement="bottom">
        <div>{record.name}</div>
    </Tooltip>
)
class UserAdd extends Component {
    static propTypes = {
        accessible: PropTypes.bool,
    };
    render() {
        const { accessible, dispatch, roles, ...props } = this.props
        if (!accessible) {
            sessionStorage.landingUrl = props.history.location.pathname
            return (<Redirect to="/login" />)
        }
        else {

            if (auth.userHasScopes(["create:users"])) {

                return (
                    <Create {...props}>
                        <SimpleForm redirect="list" toolbar={<CustomToolbarAdd />}>
                            <SelectInput
                                source="loginType"
                                choices={[
                                    { id: 'Username-Password-Authentication', name: 'Email & Password' },
                                    { id: 'google-oauth2', name: 'Google' },
                                    { id: 'microsoft', name: 'Microsoft' },
                                ]}
                                defaultValue='Username-Password-Authentication' />
                            <TextInput source="email" type="email" validate={validateRequiredEmail} />

                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    formData.loginType === 'Username-Password-Authentication' &&
                                    <div>
                                        <TextInput source="firstName" />
                                        <br />
                                        <TextInput source="familyName" />
                                    </div>
                                }
                            </FormDataConsumer>

                            <ReferenceArrayInput source="userRoles" reference="roles">
                                <SelectArrayInput optionText={<NameField />} translateChoice={false} />
                            </ReferenceArrayInput>

                            <SelectInput
                                source="language"
                                choices={[
                                    { id: 'en', name: 'main.english' },
                                    { id: 'nl', name: 'main.dutch' },
                                    { id: 'fr', name: 'main.french' },
                                    { id: 'es', name: 'main.spanish' },
                                    { id: 'da', name: 'main.danish' },
                                    { id: 'it', name: 'main.italian' },
                                    { id: 'ro', name: 'main.romanian' }
                                ]}
                                defaultValue={this.props.locale} />
                            <Field name="category" component={CategoryFilterField} />
                        </SimpleForm>

                    </Create>
                )
            } else {
                return (
                    <div></div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({
    accessible: !!state.auth.profile,
})

export default translate(connect(mapStateToProps)(UserAdd))