import FormHelperText from '@material-ui/core/FormHelperText';
import { createStyles, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { addField, translate } from 'ra-core';
import React, { Children, Component } from 'react';
import Dropzone from 'react-dropzone';
import { shallowEqual } from 'recompose';
import compose from 'recompose/compose';
import auth from '../auth';
import FileInputPreview from './FileInputPreview';
import Labeled from './Labeled';
import sanitizeRestProps from './sanitizeRestProps';


const styles = theme =>
    createStyles({
        dropZone: {
            background: "#f5f5f5",
            cursor: 'pointer',
            padding: '1rem',
            textAlign: 'center',
            color: theme.palette.text.hint,
        },
        preview: {},
        removeButton: {
            paddingTop: 10,
        },
        root: { width: '40%' },
    });

export class FileInput extends Component {
    static propTypes = {
        accept: PropTypes.string,
        children: PropTypes.element,
        classes: PropTypes.object,
        className: PropTypes.string,
        disableClick: PropTypes.bool,
        id: PropTypes.string,
        input: PropTypes.object,
        isRequired: PropTypes.bool,
        label: PropTypes.string,
        labelMultiple: PropTypes.string,
        labelSingle: PropTypes.string,
        maxSize: PropTypes.number,
        minSize: PropTypes.number,
        multiple: PropTypes.bool,
        options: PropTypes.object,
        resource: PropTypes.string,
        source: PropTypes.string,
        translate: PropTypes.func.isRequired,
        placeholder: PropTypes.node,
    };

    static defaultProps = {
        labelMultiple: 'ra.input.file.upload_several',
        labelSingle: 'ra.input.file.upload_single',
        multiple: false,
        onUpload: () => { },
        translate: id => id,
    };

    constructor(props) {
        super(props);
        let files = props.input.value || [];
        if (!Array.isArray(files)) {
            files = [files];
        }

        this.state = {
            files: files.map(this.transformFile),
        };
    }

    componentWillReceiveProps(nextProps) {
        let files = nextProps.input.value || [];
        if (!Array.isArray(files)) {
            files = [files];
        }

        this.setState({ files: files.map(this.transformFile) });
    }



    upload = async (file) => {
        const apiUrl = `${auth.apiUrl}/api`
        const a = await fetch(`${apiUrl}/files`, {
            body: file,
            headers: {
                'Content-Type': file.type,
                'Authorization': `Bearer ${auth.accessToken}`
            },
            method: 'PUT',
        }).then((resp) => resp.json())
        return a.id
    }


    onDrop = async (files) => {
        const ids = files && await Promise.all(files.map(this.upload))
        this.setState({ files: ids });
        //@ts-ignore
        ids.length > 0 && this.props.input.onBlur(ids[0])
    }

    onRemove = file => () => {
        const filteredFiles = this.state.files.filter(
            stateFile => !shallowEqual(stateFile, file)
        );
        this.setState({ files: filteredFiles });

        // Use onBlur to ensure redux-form set the input as touched
        if (this.props.multiple) {
            this.props.input.onBlur(filteredFiles);
        } else {
            this.props.input.onBlur(null);
        }
    };

    // turn a browser dropped file structure into expected structure
    transformFile = file => {
        if (!(file instanceof File)) {
            return file;
        }

        const { source, title } = Children.only(this.props.children).props;

        const transformedFile = {
            rawFile: file,
            [source]: file.preview,
        };

        if (title) {
            transformedFile[title] = file.name;
        }

        return transformedFile;
    };

    label() {
        const {
            translate,
            placeholder,
            labelMultiple,
            labelSingle,
        } = this.props;

        if (placeholder) {
            return placeholder;
        }

        if (this.props.multiple) {
            return <p>{translate(labelMultiple)}</p>;
        }

        return <p>{translate(labelSingle)}</p>;
    }

    render() {
        const {
            accept,
            children,
            classes = {},
            className,
            disableClick,
            id,
            isRequired,
            label,
            maxSize,
            meta,
            minSize,
            multiple,
            resource,
            source,
            translate,
            options = {},
            ...rest
        } = this.props;

        return (
            <Labeled
                id={id}
                label={label}
                className={classnames(classes.root, className)}
                source={source}
                resource={resource}
                isRequired={isRequired}
                meta={meta}
                {...sanitizeRestProps(rest)}
            >
                <span>
                    {
                        this.state.files.length === 0 ?
                            <Dropzone
                                onDrop={this.onDrop}
                                accept={accept}
                                disableClick={disableClick}
                                maxSize={maxSize}
                                minSize={minSize}
                                multiple={multiple}
                                className={classes.dropZone}
                                {...options}
                                inputProps={{ id, ...options.inputProps }}
                            >
                                {this.label()}
                            </Dropzone>
                            :
                            <div className="previews" style={{ position: "relative" }}>
                                {this.state.files.map((file, index) => (
                                    <FileInputPreview
                                        key={index}
                                        file={file}
                                        onRemove={this.onRemove(file)}
                                        className={classes.removeButton}
                                    >
                                        <a href={`${auth.apiUrl}/api/files/${file}`} target="_blank" rel="noreferrer noopener" >
                                            <img src={`${auth.apiUrl}/api/files/${file}`} title="logo" style={{ width: "100%", objectFit: "contain", maxHeight: "250px" }} alt="logo" />
                                        </a>
                                    </FileInputPreview>
                                ))}

                            </div>
                    }
                    {meta && meta.touched && meta.error && (
                        <FormHelperText>{translate(meta.error)}</FormHelperText>
                    )}
                </span>
            </Labeled>
        );
    }
}

export default compose(
    addField,
    translate,
    withStyles(styles)
)(FileInput);