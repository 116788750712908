import React, { useState } from 'react'
import Axios from 'axios'
import { CardActions, CreateButton, ExportButton } from 'react-admin'
import auth from '../auth'

export default ({
    basePath,
    total
}) => {

    const [loading, setLoading] = useState(false)

    const handleExport = async () => {

        try {

            console.log('Export categories started')

            if (!auth?.apiUrl) {
                console.log('Export categories error url is not set')
                return
            }

            if (!auth?.accessToken) {
                console.log('Export categories error getting token')
                return
            }

            setLoading(true)

            const response = await Axios.post(`${auth.apiUrl}/api/categories/export`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`,
                },
                responseType: 'blob',
            })

            const downloadUrl = URL.createObjectURL(response.data)
            const a = document.createElement('a')
            a.href = downloadUrl
            a.download = 'export-categories.csv'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            URL.revokeObjectURL(downloadUrl)

            console.log('Export categories successful')
        } catch (error) {
            console.log("Export categories unknown error", error)
        } finally {
            setLoading(false)
        }
    }


    return (

        <CardActions>
            {auth.userHasScopes(['create:categories']) &&
                <CreateButton basePath={basePath} />
            }
            {auth.userHasScopes(["read:categories"]) &&
                <ExportButton
                    label={loading ? "Exporting..." : "Export"}
                    disabled={total === 0 || loading}
                    onClick={handleExport}
                />
            }
        </CardActions>
    )

}
