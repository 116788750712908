import restProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'react-admin';
import auth from '../auth';
import fakeDataProvider from './fakeDataProvider';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    options.headers.set('Authorization', `Bearer ${auth.accessToken}`)
    return fetchUtils.fetchJson(url, options);
}

export default process.env.REACT_APP_BACKEND_URL ? restProvider(process.env.REACT_APP_BACKEND_URL, httpClient) : fakeDataProvider;