import Axios from 'axios'
import React, { useState } from 'react'
import { CardActions, CreateButton, ExportButton } from 'react-admin'
import auth from '../auth'

export default ({
    basePath,
    total
}) => {

    const [loading, setLoading] = useState(false)

    const handleExport = async () => {

        try {

            console.log('Export users started')

            if (!auth?.accessToken) {
                console.log('Export users error getting token')
                return
            }

            setLoading(true)
            const response = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/export`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`,
                },
                responseType: 'blob',
            })

            const downloadUrl = URL.createObjectURL(response.data)
            const a = document.createElement('a')
            a.href = downloadUrl
            a.download = 'export.csv'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            URL.revokeObjectURL(downloadUrl)

            console.log('Export users successful')
        } catch (error) {
            console.log("Export users unknown error", error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <CardActions>

            {auth.userHasScopes(["create:users"]) &&
                <CreateButton basePath={basePath} />
            }

            {auth.userHasScopes(["read:users"]) &&
                <ExportButton
                    label={loading ? "Exporting..." : "Export"}
                    disabled={total === 0 || loading}
                    onClick={handleExport}
                />
            }

        </CardActions>
    )
}
